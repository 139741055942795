import { createBrowserRouter } from "react-router-dom";
import { Blog, Contact, FuriousFictions, Home, Music, Post, ShortStories, Stories } from "./pages";
import { Layout } from "./components";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
  },
  {
    path: "/blog",
    element: (
      <Layout>
        <Blog />
      </Layout>
    ),
  },
  {
    path: "/blog/:slug",
    element: (
      <Layout>
        <Post />
      </Layout>
    ),
  },
  {
    path: "/contact",
    element: (
      <Layout>
        <Contact />
      </Layout>
    ),
  },
  {
    path: "/music",
    element: (
      <Layout>
        <Music />
      </Layout>
    ),
  },
  {
    path: "/stories",
    element: (
      <Layout>
        <Stories />
      </Layout>
    ),
  },
  {
    path: "/stories/furious-fictions",
    element: (
      <Layout>
        <FuriousFictions />
      </Layout>
    ),
  },
  {
    path: "/stories/short-stories",
    element: (
      <Layout>
        <ShortStories />
      </Layout>
    ),
  },
]);
