import book1 from '../../assets/images/stories/book-1.jpg'
import book2 from '../../assets/images/stories/book-2.jpg'

export const storyList = [
//   {
//     Title: "Guide Us Through This Blackened Night",
//     Description:
//       "A story about how we treat each other in an anthropomorphic world.",
//     Image: "",
//     To: "guide-us"
//   },
//   {
//     Title: "Cakes Across the Cosmos",
//     Description:
//       "Join Mick and his eccentric friends as they tour the galaxy to teach people the lost art of cooking.",
//     Image: "",
//     To: "cakes"
//   },
//   {
//     Title: "Bellbound",
//     Description:
//       "Detective Butcher, cursed with a critter named Bell, tries to solve a conspiracy in The Burrow.",
//     Image: "",
//     To: "bellbound"
//   },
  {
    Title: "Furious Fictions",
    Description:
      "Stories I wrote for the Australian Writers Centre's 'Furious Fictions' competitions.",
    Image: book1,
    To: "furious-fictions"
  },
  {
    Title: "Other Short Stories",
    Description:
      "Stories I've written for other competitions, or even for fun.",
    Image: book2,
    To: "short-stories"
  },
];
