/* eslint-disable jsx-a11y/anchor-has-content */
import { Paper, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { postList } from "./postList";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { GridLayout } from "../../../../components";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

export default function Post() {
  const { slug } = useParams();
  const [post, setPost] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    if (slug) {
      const data = postList.find((p) => p.Slug === slug);
      if (data) {
        loadPost(data.Markdown);
      }
    }
  }, [slug]);

  function loadPost(markdown) {
    fetch(markdown).then((data) => {
      data.text().then((text) => {
        setPost(text);
        setIsLoading(false);
      });
    });
  }

  console.log(post);

  return (
    <Stack spacing={2} sx={{ marginTop: "8em" }}>
      <GridLayout>
        <Stack spacing={8}>
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={2} sx={{ padding: "2em" }}>
                {isLoading ? (
                  <Typography variant="h2">Hang on!</Typography>
                ) : post ? (
                  <ReactMarkdown
                    children={post}
                    components={{
                      a: (props) => (
                        <a
                          {...props}
                          style={{ color: theme.palette.secondary.main }}
                          target="blank"
                        />
                      ),
                    }}
                  />
                ) : (
                  <>
                    <Typography variant="h2">Not Found:</Typography>
                    <Typography variant="h4">
                      No blog post could be found with this slug.
                    </Typography>
                  </>
                )}
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </GridLayout>
    </Stack>
  );
}
