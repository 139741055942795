import { RouterProvider } from "react-router-dom";
import { routes } from "./Routes";
import { ColourModeProvider, SideNavigatorProvider } from "./hooks";

function App() {
  return (
    <ColourModeProvider>
      <SideNavigatorProvider>
        <RouterProvider router={routes} />
      </SideNavigatorProvider>
    </ColourModeProvider>
  );
}

export default App;
