import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Paper,
  Stack,
  Typography,
  Link as MuiLink,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GridLayout } from "../../components";
import AudioPlayer from "react-h5-audio-player";
import { useState } from "react";
import { musicList } from "./musicList";
import "react-h5-audio-player/lib/styles.css";
import "./audioplayer.css";
import { socialMediaArray } from "../../data/socialMedia";
import { Link } from "react-router-dom";

export default function Music() {
  const [currentTrack, setCurrentTrack] = useState(musicList[0]);
  const [currentTrackNumber, setCurrentTrackNumber] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  function onClickPrevious() {
    const newNumber =
      currentTrackNumber === 0 ? musicList.length - 1 : currentTrackNumber - 1;
    setCurrentTrack(musicList[newNumber]);
    setCurrentTrackNumber(newNumber);
  }

  function onClickNext() {
    const newNumber =
      currentTrackNumber + 1 === musicList.length ? 0 : currentTrackNumber + 1;
    setCurrentTrack(musicList[newNumber]);
    setCurrentTrackNumber(newNumber);
  }

  function onClickTo(trackNumber) {
    setCurrentTrack(musicList[trackNumber]);
    setCurrentTrackNumber(trackNumber);
  }

  return (
    <Stack spacing={2} sx={{ marginTop: isMobile ? "4em" : "8em" }}>
      <GridLayout>
        <Stack spacing={isMobile ? 4 : 8}>
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={1} sx={{ padding: isMobile ? "1em" : "2em" }}>
                <Stack spacing={1} direction="row">
                  <Typography variant="h3">
                    I Write{" "}
                    <span style={{ color: theme.palette.secondary.main }}>
                      Music
                    </span>
                  </Typography>
                </Stack>
                <Typography variant="h4">
                  Click a button below to hear some samples.
                </Typography>
              </Stack>
            </Paper>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={2} sx={{ padding: "2em" }}>
                <Typography variant="h4">
                  Choose a 'feeling' and enjoy!
                </Typography>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  spacing={2}
                  alignItems="center"
                  sx={{ flexWrap: "wrap" }}
                >
                  {musicList.map((music, i) => {
                    return (
                      <Button
                        key={music.Title}
                        variant="outlined"
                        color="secondary"
                        onClick={() => onClickTo(i)}
                        sx={{
                          textTransform: "none",
                          marginTop: "0.5em !important",
                          marginBottom: "1em !important",
                          color: theme.palette.text.primary,
                        }}
                      >
                        {music.SubTitle}
                      </Button>
                    );
                  })}
                </Stack>
                <Card sx={{ display: "flex", flex: 1 }}>
                  <Stack sx={{ flex: 1 }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography component="div" variant="h5">
                        {currentTrack.Title}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                      >
                        {currentTrack.SubTitle}
                      </Typography>
                      <AudioPlayer
                        src={currentTrack.Src}
                        onClickNext={onClickNext}
                        onClickPrevious={onClickPrevious}
                        showSkipControls
                        showJumpControls={false}
                      />
                    </CardContent>
                  </Stack>
                  {!isMobile && (
                    <CardMedia
                      component="img"
                      sx={{ width: 200 }}
                      image={currentTrack.Image}
                    />
                  )}
                </Card>
              </Stack>
            </Paper>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={2} sx={{ padding: "2em" }}>
                <Typography variant="h4">
                  Or you can listen to them in these places:
                </Typography>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  spacing={2}
                  alignItems="center"
                  sx={{ flexWrap: "wrap" }}
                >
                  {socialMediaArray.map((socials) => {
                    return (
                      <Button
                        key={socials.Title}
                        variant="outlined"
                        color="secondary"
                        href={socials.Url}
                        startIcon={socials.Icon}
                        target="_blank"
                        sx={{ textTransform: "none", marginTop: "0.25em" }}
                      >
                        {socials.Title}
                      </Button>
                    );
                  })}
                </Stack>
                <Typography>
                  Alternatively, search for <b>Durrdiss</b> on music platforms.
                  I'll probably be there.
                </Typography>
              </Stack>
            </Paper>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={2} sx={{ padding: "2em" }}>
                <Typography variant="h4">
                  Like what you hear? Have a game, film, or other project that
                  needs music?
                </Typography>
                <Typography>
                  Then we should talk. My rates vary depending on the project's
                  budget, but are always super friendly to passionate folks
                  looking to build their dream stories.
                </Typography>
                <Typography>
                  Yes, I know it's a cliche to go 'what's your budget', but I
                  genuinely want to make good things! So, if you're making
                  something special, then I'm happy to accommodate.
                </Typography>
                <Typography>
                  If it helps, I've worked previously for between AU$60 to
                  AU$200 per minute of music created for indie works.
                </Typography>
                <MuiLink component={Link} color="secondary" to="/contact">
                  Contact me via the contact form!
                </MuiLink>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </GridLayout>
    </Stack>
  );
}
