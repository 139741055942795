import { Grid } from "@mui/material";

export default function GridLayout({ children }) {
  return (
    <Grid container columns={24}>
      <Grid item md={4} sm={2} xs={1} />
      <Grid item md={16} sm={20} xs={22}>
        {children}
      </Grid>
    </Grid>
  );
}
