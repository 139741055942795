/* eslint-disable jsx-a11y/anchor-has-content */
import {
  Paper,
  Stack,
  Typography,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { fictionList } from "./fictionList";
import { GridLayout } from "../../../../components";
import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useLocation, useNavigate } from "react-router-dom";

export default function ShortStories() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [story, setStory] = useState("");
  const [select, setSelect] = useState(hash?.substring(1) ?? "");
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (hash) {
      const fiction = fictionList.find((f) => hash.substring(1) === f.Hash);
      if (fiction) {
        loadStory(fiction.Markdown);
      }
    }
  }, [hash]);

  function loadStory(markdown) {
    fetch(markdown).then((data) => {
      data.text().then((text) => {
        setStory(text);
      });
    });
  }

  return (
    <Stack spacing={2} sx={{ marginTop: isMobile ? "4em" : "8em" }}>
      <GridLayout>
        <Stack spacing={8}>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={2} sx={{ padding: isMobile ? "1em" : "2em" }}>
                <Typography variant="h3">Short Stories</Typography>
                <Typography>
                  I've written a few short stories here and there. Some have
                  even been published!
                </Typography>
                {!select.length && (
                  <Typography>Select a story to learn more.</Typography>
                )}
                <Select
                  color="secondary"
                  variant="filled"
                  value={select}
                  sx={{
                    borderLeft: `1px solid ${theme.palette.primary.contrastText}`,
                    borderRight: `1px solid ${theme.palette.primary.contrastText}`,
                  }}
                  onChange={(e) => {
                    setSelect(e.target.value);
                    navigate(`#${e.target.value}`);
                  }}
                >
                  <MenuItem value="">Select a story</MenuItem>
                  {fictionList.map((story) => {
                    return (
                      <MenuItem key={story.Title} value={story.Hash}>
                        <Stack direction="row" spacing={2}>
                          <Typography color="text.secondary" fontWeight="bold">
                            {story.Title}
                          </Typography>
                          {!isMobile && (
                            <>
                              <Typography>{" - "}</Typography>
                              <Typography>{story.Place}</Typography>
                              {story.Accolades.length && (
                                <>
                                  <Typography>{" - "}</Typography>
                                  <Typography>{story.Accolades}</Typography>
                                </>
                              )}
                            </>
                          )}
                        </Stack>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Paper>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={2} sx={{ padding: isMobile ? "1em" : "2em" }}>
                {story && (
                  <ReactMarkdown
                    children={story}
                    components={{
                      a: (props) => (
                        <a
                          {...props}
                          style={{ color: theme.palette.secondary.main }}
                          target="blank"
                        />
                      ),
                    }}
                  />
                )}
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </GridLayout>
    </Stack>
  );
}
