import {
  Paper,
  Stack,
  Typography,
  Link as MuiLink,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { fictionList } from "./fictionList";
import { GridLayout } from "../../../../components";
import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function FuriousFictions() {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [story, setStory] = useState("");
  const [select, setSelect] = useState(hash?.substring(1) ?? "");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (hash) {
      const fiction = fictionList.find((f) => hash.substring(1) === f.Hash);
      if (fiction) {
        loadStory(fiction.Markdown);
      }
    }
  }, [hash]);

  function loadStory(markdown) {
    fetch(markdown).then((data) => {
      data.text().then((text) => {
        setStory(text);
      });
    });
  }

  return (
    <Stack spacing={2} sx={{ marginTop: isMobile ? "4em" : "8em" }}>
      <GridLayout>
        <Stack spacing={8}>
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={1} sx={{ padding: isMobile ? "1em" : "2em" }}>
                <Typography variant="h3">Furious Fictions</Typography>
                <Typography>
                  Every month, the{" "}
                  <MuiLink
                    href="https://www.writerscentre.com.au/"
                    target="blank"
                    color="secondary"
                  >
                    Australian Writers Centre
                  </MuiLink>{" "}
                  runs their{" "}
                  <MuiLink
                    href="https://www.writerscentre.com.au/furious-fiction/"
                    target="blank"
                    color="secondary"
                  >
                    'Furious Fictions'
                  </MuiLink>{" "}
                  competition. This is where a theme is given, and writers have
                  48 hours to write a max 500 word story from it.
                </Typography>
                <Typography>
                  I've entered so many of these that I figured they need their
                  own spot!
                </Typography>
                {!select.length && (
                  <Typography>Select a story to learn more.</Typography>
                )}
                <Select
                  color="secondary"
                  variant="filled"
                  value={select}
                  sx={{
                    borderLeft: `1px solid ${theme.palette.primary.contrastText}`,
                    borderRight: `1px solid ${theme.palette.primary.contrastText}`,
                  }}
                  onChange={(e) => {
                    setSelect(e.target.value);
                    navigate(`#${e.target.value}`);
                  }}
                >
                  <MenuItem value="">Select a story</MenuItem>
                  {fictionList.map((story) => {
                    return (
                      <MenuItem key={story.Title} value={story.Hash}>
                        <Stack direction="row" spacing={2}>
                          <Typography>{story.Date}</Typography>
                          <Typography color="text.secondary" fontWeight="bold">
                            {story.Title}
                          </Typography>
                          {!isMobile && (
                            <>
                              {story.Accolades.length ? (
                                <>
                                  <Typography>{" - "}</Typography>
                                  <Typography>{story.Accolades}</Typography>
                                </>
                              ) : (
                                false
                              )}
                              <Typography>{" - "}</Typography>
                              <MuiLink
                                href={story.AWCTo}
                                target="blank"
                                color="secondary"
                              >
                                <Typography>Link to the AWC</Typography>
                              </MuiLink>
                            </>
                          )}
                        </Stack>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Paper>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={2} sx={{ padding: isMobile ? "1em" : "2em" }}>
                {story && <ReactMarkdown children={story} />}
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </GridLayout>
    </Stack>
  );
}
