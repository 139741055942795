import { Paper, Stack, Typography } from "@mui/material";
import { GridLayout } from "../../components";

export default function Blog() {
  return (
    <Stack spacing={2} sx={{ marginTop: "8em" }}>
      <GridLayout>
        <Stack spacing={8}>
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={1} sx={{ padding: "2em" }}>
                <Stack spacing={1} direction="row">
                  <Typography variant="h3">Blog</Typography>
                </Stack>
                <Typography variant="h4">
                  Coming soon, once I actually finish this dang site.
                </Typography>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </GridLayout>
    </Stack>
  );
}
