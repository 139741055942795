import { Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { GridLayout } from "../../components";

export default function Contact() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Stack spacing={2} sx={{ marginTop: isMobile ? "4em" : "8em" }}>
      <GridLayout>
        <Stack spacing={8}>
          <Paper sx={{ display: "flex", flex: 1 }}>
            <Stack spacing={1} sx={{ padding: isMobile ? "1em" : "2em" }}>
              <Typography variant="h3">Contact Form</Typography>
              <Typography variant="h5">
                Coming soon, once I actually finish this dang site. You can
                email richardmgaynor(at)gmail.com in the meantime.
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </GridLayout>
    </Stack>
  );
}
