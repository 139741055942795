import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#080D10",
      dark: "#BF5B04",
      contrastText: "#FCE2A6",
    },
    secondary: {
      main: "#FE3D26",
      light: "#A67E4E",
      contrastText: "#080D10",
      dark: "#593F27",
    },
    background: {
      default: "#080D10",
      dark: "#262626",
      vibrant: "#BF5B04",
      paper: "#18262E",
    },
    text: {
      primary: "#FCE2A6",
      secondary: "#BF3434",
      disabled: "rgba(235,239,242,0.5)",
      hint: "rgba(235,239,242,0.5)",
    },
    divider: "#FE3D26",
  },
  typography: {
    h1: {
      fontFamily: "Bebas Neue",
    },
    h2: {
      fontFamily: "Bebas Neue",
    },
    h3: {
      fontFamily: "Bebas Neue",
    },
    h4: {
      fontFamily: "Bebas Neue",
    },
    h5: {
      fontFamily: "Bebas Neue",
    },
    h6: {
      fontFamily: "Bebas Neue",
    },
    fontFamily: "Epilogue",
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
});

export const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#080D10",
      dark: "#415AA6",
      contrastText: "#000",
    },
    secondary: {
      main: "#BF3F3F",
      light: "#A67E4E",
      contrastText: "#000",
      dark: "#403C38",
    },
    background: {
      default: "#dbcfb6",
      dark: "#262626",
      vibrant: "#F2BB77",
      paper: "#F2E6CE",
    },
    text: {
      primary: "#000",
      secondary: "#415AA6",
      disabled: "rgba(235,239,242,0.5)",
      hint: "rgba(235,239,242,0.5)",
    },
    divider: "#BF3F3F",
  },
  typography: {
    h1: {
      fontFamily: "Bebas Neue",
    },
    h2: {
      fontFamily: "Bebas Neue",
    },
    h3: {
      fontFamily: "Bebas Neue",
    },
    h4: {
      fontFamily: "Bebas Neue",
    },
    h5: {
      fontFamily: "Bebas Neue",
    },
    h6: {
      fontFamily: "Bebas Neue",
    },
    fontFamily: "Epilogue",
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
});
