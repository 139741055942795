import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { ReactComponent as SoundcloudIcon } from "../assets/images/soundcloud-icon.svg";
import { SvgIcon } from "@mui/material";

export const socialMediaArray = [
  {
    Title: "YouTube (@Durrdiss)",
    Icon: <YouTubeIcon />,
    Url: "https://www.youtube.com/channel/UCmWnIJSFIgZUcUcRIrG71XQ",
  },
  {
    Title: "Bandcamp (@Durrdiss)",
    Icon: <MusicNoteIcon />,
    Url: "https://durrdiss.bandcamp.com",
  },
  {
    Title: "Twitter (@Durrdiss)",
    Icon: <TwitterIcon />,
    Url: "https://twitter.com/Durrdiss",
  },
  {
    Title: "Soundcloud (Though I don't post new things there)",
    Icon: (
      <SvgIcon>
        <SoundcloudIcon height="20px" width="20px" />
      </SvgIcon>
    ),
    Url: "https://soundcloud.com/drdiss",
  },
];
