import {
  AppBar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { menuList } from "../../common";
import { useColourMode } from "../../hooks";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

export default function MobileHeader() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { lightMode, setLightMode } = useColourMode();

  return (
    <AppBar
      position="absolute"
      color="secondary"
      sx={{
        height: "3em",
        width: "101%",
        zIndex: 100000,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: "center", flex: 1, padding: "0 1em" }}
      >
        <IconButton onClick={() => setOpen(!open)}>
          <MenuIcon fontSize="large" />
        </IconButton>
        <Button onClick={() => navigate("/")}>
          <Typography variant="h4" fontWeight="bold">
            Richard Gaynor
          </Typography>
        </Button>
      </Stack>
      <Drawer anchor="top" open={open} onClose={() => setOpen(false)}>
        <Stack sx={{ paddingTop: "3em" }}>
          <List>
            {menuList.map((item) => {
              return (
                <ListItem key={item.title} disablePadding>
                  <ListItemButton onClick={() => navigate(item.to)}>
                    <ListItemIcon>{item.Icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </ListItem>
              );
            })}
            <ListItem disablePadding>
              <ListItemButton onClick={() => setLightMode(!lightMode)}>
                <ListItemIcon>
                  {lightMode ? (
                    <LightbulbIcon color="secondary" />
                  ) : (
                    <LightbulbOutlinedIcon color="secondary" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={lightMode ? "Light Theme" : "Dark Theme"}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Drawer>
    </AppBar>
  );
}
