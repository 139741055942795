import centidog from "./pages/centidog.md";
import aLittleLove from "./pages/a-little-love.md";
import differentTastes from "./pages/different-tastes.md";
import fretting from "./pages/fretting.md";
import aSpeckOfSafety from "./pages/a-speck-of-safety.md";
import captiveAudience from "./pages/captive-audience.md";
import activeImagination from "./pages/active-imagination.md";
import hornedEntrepeneur from "./pages/horned-entrepeneur.md";

export const fictionList = [
  {
    Date: "September 2022",
    Title: "Centidog",
    Accolades: "Shortlisted!",
    AWCTo:
      "https://www.writerscentre.com.au/blog/furious-fiction-september-2022-winner-and-shortlist/",
    Markdown: centidog,
    Hash: "centidog",
  },
  {
    Date: "December 2022",
    Title: "A Little Love",
    Accolades: "Longlisted!",
    AWCTo:
      "https://www.writerscentre.com.au/blog/furious-fiction-december-2022-winner-and-shortlist/",
    Markdown: aLittleLove,
    Hash: "a-little-love",
  },
  {
    Date: "March 2023",
    Title: "Different Tastes",
    Accolades: "",
    AWCTo:
      "https://www.writerscentre.com.au/blog/furious-fiction-march-2023-showcase/",
    Markdown: differentTastes,
    Hash: "different-tastes",
  },
  {
    Date: "April 2023",
    Title: "Fretting",
    Accolades: "Longlisted!",
    AWCTo:
      "https://www.writerscentre.com.au/blog/furious-fiction-april-2023-story-showcase/",
    Markdown: fretting,
    Hash: "fretting",
  },
  {
    Date: "May 2023",
    Title: "A Speck of Safety",
    Accolades: "",
    AWCTo:
      "https://www.writerscentre.com.au/blog/furious-fiction-may-2023-story-showcase/",
    Markdown: aSpeckOfSafety,
    Hash: "a-speck-of-safety",
  },
  {
    Date: "June 2023",
    Title: "Captive Audience",
    Accolades: "Shortlisted!",
    AWCTo:
      "https://www.writerscentre.com.au/blog/furious-fiction-june-2023-story-showcase/",
    Markdown: captiveAudience,
    Hash: "captive-audience",
  },
  {
    Date: "July 2023",
    Title: "Active Imagination",
    Accolades: "",
    AWCTo:
      "https://www.writerscentre.com.au/blog/furious-fiction-july-2023-story-showcase/",
    Markdown: activeImagination,
    Hash: "active-imagination",
  },
  {
    Date: "August 2023",
    Title: "Horned Entrepeneur",
    Accolades: "",
    AWCTo:
      "https://www.writerscentre.com.au/blog/furious-fiction-august-2023-story-showcase/",
    Markdown: hornedEntrepeneur,
    Hash: "horned-entrepeneur",
  },
];
