import { createContext, useContext, useState } from "react";

const SideNavigatorContext = createContext();

export const useSideNavigator = () => useContext(SideNavigatorContext);

export function SideNavigatorProvider({ children }) {
  const [sideNavigation, setSideNavigation] = useState([]);

  return (
    <SideNavigatorContext.Provider
      value={{ sideNavigation, setSideNavigation }}
    >
      {children}
    </SideNavigatorContext.Provider>
  );
}
