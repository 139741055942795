import { CssBaseline, ThemeProvider } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { darkTheme, lightTheme } from "../theme/theme";

const ColourModeContext = createContext();

export const useColourMode = () => useContext(ColourModeContext);

const lightModeName = "lightMode";

export function ColourModeProvider({ children }) {
  const [lightMode, setLightMode] = useState(getSavedColorMode());

  function getSavedColorMode() {
    const existing = localStorage.getItem(lightModeName);
    if (existing) {
      return existing === "true";
    }

    return false;
  }

  function saveColorMode(mode) {
    setLightMode(mode);
    localStorage.setItem(lightModeName, mode);
  }

  return (
    <ThemeProvider theme={lightMode ? lightTheme : darkTheme}>
      <ColourModeContext.Provider
        value={{ lightMode, setLightMode: saveColorMode }}
      >
        <CssBaseline />
        {children}
      </ColourModeContext.Provider>
    </ThemeProvider>
  );
}
