import { useMediaQuery } from "@mui/material";
import Header from "../header/Header";
import SideMenu from "../sidemenu/SideMenu";
import MobileHeader from "../header/MobileHeader";

export default function Layout({ children }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      {isMobile ? (
        <MobileHeader />
      ) : (
        <>
          <Header />
          <SideMenu />
        </>
      )}
      {children}
    </>
  );
}
