import {
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Stack,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { styled, useTheme } from "@mui/material/styles";
import { useColourMode, useSideNavigator } from "../../hooks";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { menuList } from "../../common";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const FancyTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    padding: "1em",
    fontSize: 14,
  },
}));

export default function SideMenu() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { sideNavigation } = useSideNavigator();
  const { lightMode, setLightMode } = useColourMode();

  const [smileyHover, setSmileyHover] = useState(false);

  return (
    <Drawer variant="permanent" open={false} sx={{ width: "5em" }}>
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.palette.secondary.main,
          paddingTop: "3em",
        }}
      >
        <IconButton
          onMouseEnter={() => setSmileyHover(true)}
          onMouseLeave={() => setSmileyHover(false)}
          onClick={() => navigate("/")}
        >
          {smileyHover ? (
            <SentimentVerySatisfiedIcon
              sx={{ color: theme.palette.secondary.contrastText }}
            />
          ) : (
            <SentimentSatisfiedIcon
              sx={{ color: theme.palette.secondary.contrastText }}
            />
          )}
        </IconButton>
      </Stack>
      <Stack spacing={2} sx={{ justifyContent: "center", margin: "1em 0" }}>
        {menuList.map((item) => {
          return (
            <FancyTooltip key={item.title} title={item.title} placement="right">
              <IconButton color="secondary" onClick={() => navigate(item.to)}>
                {item.Icon}
              </IconButton>
            </FancyTooltip>
          );
        })}
      </Stack>
      <Divider variant="middle" />
      <Stack spacing={2} sx={{ justifyContent: "center", flex: 1 }}>
        {sideNavigation.map((side) => (
          <FancyTooltip key={side.title} title={side.title} placement="right">
            <IconButton color="secondary" onClick={() => navigate(side.url)}>
              {side.icon}
            </IconButton>
          </FancyTooltip>
        ))}
      </Stack>
      <Stack>
        <FancyTooltip title="Switch Themes" placement="right">
          <IconButton
            color="secondary"
            onClick={() => setLightMode(!lightMode)}
          >
            {lightMode ? <LightbulbIcon /> : <LightbulbOutlinedIcon />}
          </IconButton>
        </FancyTooltip>
      </Stack>
    </Drawer>
  );
}
