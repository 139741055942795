import {
  CardMedia,
  Paper,
  Stack,
  Typography,
  Link as MuiLink,
  Button,
  useMediaQuery,
} from "@mui/material";
import { GridLayout } from "../../components";
import teenie from "../../assets/images/teenie.jpg";
import cubase from "../../assets/images/cubase.jpg";
import yarn from "../../assets/images/yarn.jpg";
import { Link } from "react-router-dom";
import { socialMediaArray } from "../../data/socialMedia";

export default function Home() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Stack spacing={2} sx={isMobile ? { marginTop: "4em" } : {}}>
      {!isMobile && (
        <CardMedia
          component="img"
          height="400"
          image={teenie}
          alt="My cat, Teenie, snoozing menacingly."
          sx={{ width: "100%" }}
        />
      )}
      <GridLayout>
        <Stack spacing={8} sx={{ marginBottom: "2em" }}>
          <Paper>
            <Stack spacing={1} sx={{ padding: "2em" }}>
              <Stack spacing={1} direction="row">
                <Typography variant="h4">
                  {"Hi! I'm "}
                  <MuiLink
                    component={Link}
                    color="secondary"
                    to="/"
                    sx={{ textDecoration: "none" }}
                  >
                    Richard Gaynor
                  </MuiLink>
                </Typography>
              </Stack>
              <Typography variant="h3">Author, Composer, Programmer</Typography>
              <Typography>And this is my website. (WIP)</Typography>
            </Stack>
          </Paper>
          <Paper>
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ justifyContent: "space-between" }}
            >
              <img
                src={yarn}
                alt="A screenshot of a Yarn graph of an old project. Yarn is fun - you should check it out."
              />
              <Stack spacing={2} sx={{ padding: "2em" }}>
                <Stack spacing={1} direction={isMobile ? "column" : "row"}>
                  <Typography variant="h4">
                    {"I write "}
                    <MuiLink component={Link} color="secondary" to="/stories">
                      stories.
                    </MuiLink>
                  </Typography>
                  <Typography variant="h4">Sometimes they're good.</Typography>
                </Stack>
                <Typography>
                  I am an avid writer of magic, fantasy, fun, and mystery. Check
                  some out below (if I bothered to add any).
                </Typography>
              </Stack>
            </Stack>
          </Paper>
          <Paper>
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ justifyContent: "space-between" }}
            >
              {isMobile && (
                <img
                  src={cubase}
                  alt="A screenshot of Cubase, a Digital Audio Workstation you can make music in"
                />
              )}
              <Stack spacing={2} sx={{ padding: "2em" }}>
                <Stack spacing={1} direction={isMobile ? "column" : "row"}>
                  <Typography variant="h4">
                    {"I write "}
                    <MuiLink component={Link} color="secondary" to="/music">
                      music.
                    </MuiLink>
                  </Typography>
                  <Typography variant="h4">
                    It's also sometimes good.
                  </Typography>
                </Stack>
                <Typography>
                  With over a decade in musical experience, music is one of my
                  biggest passions! Check out my socials below (that I sometimes
                  update), or check out the{" "}
                  <MuiLink component={Link} color="secondary" to="/music">
                    music page.
                  </MuiLink>
                  .
                </Typography>
                {socialMediaArray.map((socials) => {
                  return (
                    <Button
                      key={socials.Title}
                      variant="outlined"
                      color="secondary"
                      href={socials.Url}
                      startIcon={socials.Icon}
                      target="_blank"
                      sx={{ textTransform: "none", marginTop: "0.25em" }}
                    >
                      {socials.Title}
                    </Button>
                  );
                })}
              </Stack>
              {!isMobile && (
                <img
                  src={cubase}
                  alt="A screenshot of Cubase, a Digital Audio Workstation you can make music in"
                />
              )}
            </Stack>
          </Paper>
          {/* <Paper>
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ justifyContent: "space-between" }}
            >
              <img src={blog} alt="A business man typing on a laptop" />
              <Stack spacing={2} sx={{ padding: "2em" }}>
                <Stack spacing={1} direction={isMobile ? "column" : "row"}>
                  <Typography variant="h4">
                    {"I have a "}
                    <MuiLink component={Link} color="secondary" to="/blog">
                      blog.
                    </MuiLink>
                  </Typography>
                  <Typography variant="h4">It's usually bad.</Typography>
                </Stack>
                <Typography>
                  But it's where I can occasionally chat about fun stuff. It's
                  still being made, but one day I'll have something to say.
                </Typography>
              </Stack>
            </Stack>
          </Paper> */}
        </Stack>
      </GridLayout>
    </Stack>
  );
}
