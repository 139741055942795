import {
  Paper,
  Stack,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  CardActionArea,
  useMediaQuery,
} from "@mui/material";
import { GridLayout } from "../../components";
import { storyList } from "./storyList";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function Stories() {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Stack spacing={2} sx={{ marginTop: isMobile ? "4em" : "8em" }}>
      <GridLayout>
        <Stack spacing={8}>
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Stack spacing={1} sx={{ padding: isMobile ? "1em" : "2em" }}>
                <Stack spacing={1} direction="row">
                  <Typography variant="h3">
                    I Write{" "}
                    <span style={{ color: theme.palette.secondary.main }}>
                      Stories
                    </span>
                  </Typography>
                </Stack>
                <Typography variant="h4">
                  And you can read some right here
                </Typography>
                <Typography>With more coming soon</Typography>
              </Stack>
            </Paper>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Paper sx={{ display: "flex", flex: 1 }}>
              <Grid
                container
                spacing={2}
                sx={{ padding: isMobile ? "1em" : "2em" }}
              >
                {storyList.map((story) => {
                  return (
                    <Grid item xs={12} sm={4} md={3} key={story.Title}>
                      <Card>
                        <CardActionArea LinkComponent={Link} to={story.To}>
                          <CardMedia
                            sx={{ height: 140 }}
                            image={story.Image}
                            title={story.Title}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5">
                              {story.Title}
                            </Typography>
                            <Typography color="text.secondary">
                              {story.Description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
          </Stack>
        </Stack>
      </GridLayout>
    </Stack>
  );
}
