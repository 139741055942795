import bowwow from '../../assets/images/music/bowwow.jpg'
import ranga from '../../assets/images/music/ranga.jpg'
import kitsune from '../../assets/images/music/kitsune.jpg'
import shrek from '../../assets/images/music/shrekgodhand.jpg'
import spooky from '../../assets/images/music/spooky.jpg'
import izana from '../../assets/images/music/izana.jpg'
import piano from '../../assets/images/music/piano.jpg'
import harmony from '../../assets/images/music/harmony.jpg'
import tree from '../../assets/images/music/tree.jpg'

export const musicList = [
    {
        Title: 'Bow-A-Bow Wow',
        SubTitle: 'Jazz, Metal, Saucy',
        Image: bowwow,
        Src: 'https://durrdiss.blob.core.windows.net/music/Bow-wow-0-4.mp3'
    },
    {
        Title: 'Boss Fight',
        SubTitle: 'Funky, Epic, Bossfight!',
        Image: izana,
        Src: 'https://durrdiss.blob.core.windows.net/music/07-MonoBoss-soundtrack.mp3'
    },
    {
        Title: 'Spookie Cookie ',
        SubTitle: 'Halloween, Electronic, Dance',
        Image: spooky,
        Src: 'https://durrdiss.blob.core.windows.net/music/SpookieCookie-0-5.mp3'
    },
    {
        Title: 'Kitsune Village',
        SubTitle: 'JRPG, Friendly, Town',
        Image: kitsune,
        Src: 'https://durrdiss.blob.core.windows.net/music/26-Kitsune.mp3'
    },
    {
        Title: 'Phat Stars',
        SubTitle: 'Scifi, Wonder, Space',
        Image: shrek,
        Src: 'https://durrdiss.blob.core.windows.net/music/PhatStars.mp3'
    },
    {
        Title: 'Mono Tomb',
        SubTitle: 'Spooky, Funky, Castlevania',
        Image: izana,
        Src: 'https://durrdiss.blob.core.windows.net/music/09-MonoTomb-soundtrack.mp3'
    },
    {
        Title: 'Gala Waltz',
        SubTitle: 'Classical, Waltz, Regal',
        Image: kitsune,
        Src: 'https://durrdiss.blob.core.windows.net/music/19-GalaWaltz.mp3'
    },
    {
        Title: 'Mono Laboratory',
        SubTitle: 'Fun, Mysterious, Retro',
        Image: izana,
        Src: 'https://durrdiss.blob.core.windows.net/music/12-MonoLab-soundtrack.mp3'
    },
    {
        Title: 'Containment',
        SubTitle: 'Scifi, Metroid, Lonely',
        Image: kitsune,
        Src: 'https://durrdiss.blob.core.windows.net/music/30-Containment.mp3'
    },
    {
        Title: 'Puzzle Party',
        SubTitle: 'Retro, Silly, Party!',
        Image: kitsune,
        Src: 'https://durrdiss.blob.core.windows.net/music/27-PuzzleParty.mp3'
    },
    {
        Title: 'Lonely Rabbit',
        SubTitle: 'Piano, Lullaby, Melancholy',
        Image: piano,
        Src: 'https://durrdiss.blob.core.windows.net/music/10 - Lonely Rabbit.mp3'
    },
    {
        Title: 'Harmony',
        SubTitle: 'Princess, Vocaloid, Hiphop',
        Image: harmony,
        Src: 'https://durrdiss.blob.core.windows.net/music/05 - Harmony.mp3'
    },
    {
        Title: 'Desert Noir',
        SubTitle: 'Desert, Night, Mystery',
        Image: ranga,
        Src: 'https://durrdiss.blob.core.windows.net/music/DesertNoir-0-1.mp3'
    },
    {
        Title: 'Didgeridevastation',
        SubTitle: 'Didgeridoo, Distortion, Hell',
        Image: tree,
        Src: 'https://durrdiss.blob.core.windows.net/music/Didgeridevastation.mp3'
    }
]

