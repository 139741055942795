import zelda23 from './pages/05-08-23-Zelda-TotK.md'

export const postList = [
  {
    Date: "05/08/2023",
    Title: "Zelda: Tears of the Kingdom - It Was Alright",
    Markdown: zelda23,
    Slug: "zelda-tears-kingdom-story",
    Tags: ["games", "nintendo", "storytelling", "review", "zelda"],
  },
];
