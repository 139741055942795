import quack from "./pages/quack.md";
import funnyStuff from "./pages/funny-stuff.md";

export const fictionList = [
  {
    Place: "Sydney Hammond Memorial Short Story Competition - 2022",
    Title: "Quack",
    Accolades: "Longlisted!",
    Markdown: quack,
    Hash: "quack",
  },
  {
    Place: "Twisted Stringybark Award -  2022",
    Title: "Funny Stuff",
    Accolades: "Shortlisted!",
    Markdown: funnyStuff,
    Hash: "funny-stuff",
  },
];
