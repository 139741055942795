import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import EmailIcon from "@mui/icons-material/Email";

export const menuList = [
  {
    title: "Stories",
    to: "/stories",
    Icon: <AutoStoriesIcon color="secondary" />,
  },
  {
    title: "Music",
    to: "/music",
    Icon: <MusicNoteIcon color="secondary" />,
  },
  // {
  //   title: "Blog",
  //   to: "/blog",
  //   Icon: <BookIcon color="secondary" />,
  // },
  {
    title: "Contact",
    to: "/contact",
    Icon: <EmailIcon color="secondary" />,
  },
];
