import { AppBar, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  return (
    <AppBar
      position="absolute"
      color="secondary"
      sx={{
        height: "6em",
        transform: "rotate(-2deg)",
        top: "-2.1em",
        width: "101%",
        zIndex: 100000,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: "end", flex: 1, padding: "1em 2em" }}
      >
        <Button onClick={() => navigate("/")}>
          <Typography variant="h4" fontWeight="bold">
            Richard Gaynor
          </Typography>
        </Button>
      </Stack>
    </AppBar>
  );
}
